import { GridCellParams } from '@mui/x-data-grid-premium'
import { getPlacement } from './heleprs/getPlacement/getPlacement'
import { getTitle } from './heleprs/getTitle/getTitle'
import { getPlacementOffsetModifier } from './heleprs/getPlacementOffsetModifier/getPlacementOffsetModifier'

export const getTooltipProps = (cellParams: GridCellParams) => {
  const title = getTitle(cellParams)

  if (!title || cellParams.cellMode === 'edit') {
    return null
  }

  const placement = getPlacement(cellParams)
  const placementOffsetModifier = getPlacementOffsetModifier(placement)

  return {
    title,
    placement,
    slotProps: {
      popper: {
        modifiers: [placementOffsetModifier],
      },
    },
  }
}
