import { GridCellParams } from '@mui/x-data-grid-premium'

export const getPlacement = ({ colDef }: GridCellParams) => {
  const { align } = colDef

  switch (align) {
    case 'right':
      return 'bottom-end' as const
    case 'center':
      return 'bottom' as const
    default:
      return 'bottom-start' as const
  }
}
