import { TDensity } from './helpers/createDensityStore/createDensityStore'

export const ROW_HEIGHT: Record<TDensity, number> = { default: 40, relaxed: 48 }
export const HEADER_HEIGHT: Record<TDensity, number> = { default: 32, relaxed: 40 }

export const suiGridClasses = {
  borderRight: 'border-right',
  borderLeft: 'border-left',
  numericTabularNums: 'numeric-tabular-nums',
  bold: 'bold',
  position: 'relative',
}
