import { useCallback, useState } from 'react'
import {
  GridCellParams,
  GridCellProps,
  gridClasses,
  GridColumnResizeParams,
  useGridApiContext,
  useGridApiEventHandler,
} from '@mui/x-data-grid-premium'
import { isOverflown } from './helpers/isOverflown/isOverflown'

type TParams = Pick<GridCellProps, 'column' | 'rowId'>

export const useGridCellOverflow = ({ column, rowId }: TParams) => {
  const apiRef = useGridApiContext()
  const [hasOverflow, setHasOverflow] = useState(false)

  const handleColumnWidthChange = useCallback(
    (params: GridColumnResizeParams) => {
      if (params.colDef.field !== column.field) {
        return
      }
      setHasOverflow(false)
    },
    [column, setHasOverflow],
  )

  const handleCellMouseOver = useCallback(
    (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
      if (params.id !== rowId || params.field !== column.field) {
        return
      }
      const currentTarget = event.target as HTMLElement
      const target = currentTarget.closest(`.${gridClasses.cell}`)

      if (!target) {
        setHasOverflow(false)
        return
      }
      const hasOverflow = isOverflown(target)

      setHasOverflow(hasOverflow)
    },
    [column, setHasOverflow, rowId],
  )

  useGridApiEventHandler(apiRef, 'columnWidthChange', handleColumnWidthChange)
  useGridApiEventHandler(apiRef, 'cellMouseOver', handleCellMouseOver)

  return hasOverflow
}
