import { useEffect } from 'react'
import { Router } from 'next/router'
import { RouterNavigationTiming } from './helpers/RouterNavigationTiming/RouterNavigationTiming'

export const useTrackRouterTimings = () => {
  useEffect(() => {
    const { start, complete, clear } = new RouterNavigationTiming()

    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', complete)
    Router.events.on('routeChangeError', clear)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', complete)
      Router.events.off('routeChangeError', clear)
    }
  }, [])
}
