import { isNumber, isString } from 'lodash'
import { GridCellParams } from '@mui/x-data-grid-premium'

export const getTitle = (cellParams: GridCellParams) => {
  const title = cellParams.formattedValue ?? cellParams.value ?? ''

  if (title === '—') {
    return null
  }
  const hasRenderValue = isString(title) || isNumber(title)

  return hasRenderValue ? title : null
}
