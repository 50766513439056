import { addAction } from 'lib/tracking/datadog'

export class RouterNavigationTiming {
  private startTime: Maybe<number> = null
  private shallow: Maybe<boolean> = null
  private fromPathname: Maybe<string> = null
  private fromParams: Maybe<string> = null
  private toPathname: Maybe<string> = null
  private toParams: Maybe<string> = null

  private formatPathnameForDataDog(pathname: string) {
    return pathname.replace(/\/\d+\//, '/?/')
  }

  private parsePath(path: string) {
    const [pathname = '', params = ''] = path.split('?')

    return [this.formatPathnameForDataDog(pathname), params] as const
  }

  private getTimestamp() {
    return Date.now()
  }

  start = (url: string, context: { shallow: boolean }) => {
    const [fromPathname, fromParams] = this.parsePath(`${window.location.pathname}${window.location.search}`)
    const [toPathname, toParams] = this.parsePath(url)

    if (toPathname === fromPathname) {
      this.clear()
    } else {
      this.startTime = this.getTimestamp()
      this.shallow = context.shallow

      this.fromPathname = fromPathname
      this.fromParams = fromParams

      this.toPathname = toPathname
      this.toParams = toParams
    }
  }

  complete = () => {
    if (this.startTime) {
      const duration = this.getTimestamp() - this.startTime

      addAction('Next.js navigation', {
        fromPathname: this.fromPathname,
        fromParams: this.fromParams,
        toPathname: this.toPathname,
        toParams: this.toParams,
        shallow: this.shallow,
        duration,
      })

      this.clear()
    }
  }

  clear = () => {
    this.startTime = null
    this.shallow = null
    this.fromPathname = null
    this.fromParams = null
    this.toPathname = null
    this.toParams = null
  }
}
