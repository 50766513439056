import MuiGlobalStyles from '@mui/material/GlobalStyles'
import { classNames } from 'settle-ui/theme'

export const GlobalStyles: React.FC = () => (
  <MuiGlobalStyles
    styles={({ breakpoints }) => ({
      '.intercom-app iframe[name="intercom-banner-frame"]': {
        zIndex: 2,
      },
      input: {
        // fix for iOS Safari zooming on focus
        [breakpoints.down('mobile')]: {
          '&:focus': {
            fontSize: 'inherit',
          },
        },
      },
      [`.${classNames.obtrusiveScrollbar} *`]: {
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          background: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '12px',
          background: '#c1c1c1',
          cursor: 'grab',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#b4b4b4',
        },
        '&::-webkit-scrollbar-thumb:active': {
          background: '#a8a8a8',
        },
      },
    })}
  />
)
