import {
  GridCellProps,
  GridCell as MuiGridCell,
  useGridApiContext,
  useGridSelector,
  GridColDef,
  GridCellParams,
} from '@mui/x-data-grid-premium'
import { Tooltip, TooltipProps } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ForwardedRef, forwardRef, useMemo } from 'react'
import isFunction from 'lodash/isFunction'
import { getTooltipProps } from './helpers/getTooltipProps/getTooltipProps'
import { useGridCellOverflow } from './hooks/useGridCellOverflow/useGridCellOverflow'

type TExtendedGridColDef = GridColDef & {
  getTooltipTitle?: (cellParams: GridCellParams) => TooltipProps['title'] | null
}

type TExtendedGridCellProps = GridCellProps & {
  column: TExtendedGridColDef
}

export const GridCell = forwardRef((props: TExtendedGridCellProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { rowId, column } = props
  const apiRef = useGridApiContext()
  const { standardGridTooltips } = useFlags()
  const cellParams = useGridSelector(apiRef, () => {
    try {
      return apiRef.current.getCellParams(rowId, column.field)
    } catch (error) {
      return null
    }
  })

  const renderedCell = <MuiGridCell ref={ref} {...props} />
  const hasOverflow = useGridCellOverflow({ column, rowId })
  const getTooltipTitleOverride = column.getTooltipTitle
  const hasTooltipTitleOverride = isFunction(column.getTooltipTitle)

  const tooltipProps = useMemo(() => {
    if (!cellParams || !standardGridTooltips) {
      return null
    }
    const defaultTooltipProps = getTooltipProps(cellParams)

    return { ...defaultTooltipProps, ...(getTooltipTitleOverride && { title: getTooltipTitleOverride(cellParams) }) }
  }, [cellParams, getTooltipTitleOverride, standardGridTooltips])

  if (!tooltipProps || !tooltipProps.title) {
    return renderedCell
  }

  const showTooltip = hasOverflow || hasTooltipTitleOverride

  if (showTooltip && tooltipProps) {
    const { title, ...restTooltipProps } = tooltipProps

    return (
      <Tooltip title={title} {...restTooltipProps}>
        {renderedCell}
      </Tooltip>
    )
  }

  return renderedCell
})

GridCell.displayName = 'GridCell'
