export const getPlacementOffsetModifier = (placement: string) => {
  let offset = [0, 0]

  if (placement === 'bottom-start' || placement === 'top-start') {
    offset = [8, 0]
  }
  if (placement === 'bottom-end' || placement === 'top-end') {
    offset = [-8, 0]
  }

  return {
    name: 'offset',
    options: {
      offset,
    },
  }
}
